@import url('https://fonts.googleapis.com/css?family=Alata&display=swap');

html, body {
    font-family: 'Alata', sans-serif;
}

.col {
    align-items: center;
    background-color:white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-align: center;
}

.row {
    margin-bottom: 0;
}


/* HOME PAGE START */
#joeKamala {
    margin-top: 500px;
    width: 90vw;
}

#homeDescription {
    color: #003366;
    font-size: 7vmin;
    width: 70%;
}

.joinButton {
    background-color: #e06666ff;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 10px 10px #00000010;
    color: white;
    font-size: 22.5px;
    height: 50px;
    transition-duration: 500ms;
    width: 60%;
}

.joinButton:hover {
    background-color: rgb(202, 89, 89);
    transition-duration: 500ms;
}

.joinButton:focus {
    outline: none;
}
/* HOME PAGE END */

/* ABOUT PAGE START */
#aboutText {
    color: #003366;
    font-size: 7vmin;
    padding: 30px;
    margin: 0;
    text-align: left;
    width: 80%;
}

.humanityFirst {
    color: #e06666ff;
}
/* ABOUT PAGE END */

/* TEAM PAGE START */
#teamRow {
    flex-wrap: wrap;
    justify-content: space-around;
    padding-top: 20px;
}

.bio {
    background-color: rgba(211, 211, 211, 0.2);
    border-radius: 10px;
    box-shadow: 1px 1px 15px lightgray;
    color: #003366;
    margin-bottom: 20px;
    padding: 40px;
    position: relative;
    height: 300px;
    width: 95%;
}

.bio-description {
    font-size: 14px;
    text-align: left;
}

.bio-image {
    border-radius: 50%;
    height: 50px;
    position: absolute;
    right: 10%;
    top: 10%;
    width: 50px;
}

.bio-title {
    background-color: #e06666ff;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    padding: 2px 5px;
}
/* TEAM PAGE END */

/* JOIN PAGE START */
#yangDancing {
    background-image: url('./../UCBYangGangMedia/Animations/dancingYang.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: none;
    height: 200px;
    width: 200px;
}

.form-control::placeholder {
    color: #003366de;
} 

.form-control {
    background: none;
    border: none;
    border-radius: 0;
    border-bottom: 1.5px solid #003366;
    color: #003366;
    outline: none;
    transition-duration: 250ms;
    transition-property: all;
    width: 80%;
}

.form-control:disabled {
    background-color: transparent;
}

.form-control:focus {
    background: none;
    border-bottom: 1.5px solid #003366;
    color: #003366;
   
    -webkit-box-shadow: none;
    box-shadow: none;
}

#nameForm {
    margin-bottom: 15px;
}

#yearForm {
    margin-bottom: 35px;
    margin-top: 15px;
}

#submitAnotherResponseButton {
    background-color: transparent;
    border-color: transparent;
    color: #003366de;
    display: none;
}

#submitAnotherResponseButton:focus {
    outline: none;
}
/* JOIN PAGE END */

/* COPYRIGHT PAGE START */
#copyrightRow {
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.socialMediaLogo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 0px;
    height: 40px;
    margin-right: 7px;
    width: 40px;
}

#emailLogo {
    background-image: url('./../UCBYangGangMedia/Logos/EmailLogo.png');
}

#slackLogo {
    background-image: url('./../UCBYangGangMedia/Logos/SlackLogo.png');
}

#facebookLogo {
    background-image: url('./../UCBYangGangMedia/Logos/FacebookLogo.png');
}

#instagramLogo {
    background-image: url('./../UCBYangGangMedia/Logos/InstagramLogo.jpg');
}

#twitterLogo {
    background-image: url('./../UCBYangGangMedia/Logos/TwitterLogo.png');
}
/* COPYRIGHT PAGE END */


/* Some adjustments for larger screens. */
@media (min-width: 1024px) {
    #menuButton {
        left: 5%;
    }

    #homeDescription {
        width: 100%;
    }

    #joinButtonTop {
        margin-top: 10px;
        width: 30%;
    }

    #joinButtonBottom {
        margin-top: 10px;
        width: 20%;
    }

    .bio {
        width: 30%;
    }

    .form-control {
        width: 60%;
    }

    #backButton {
        right: 5%;
    }

    .menuButtonItem {
        font-size: 35px;
    }
}
